import { Clipboard } from '@angular/cdk/clipboard';
import { ConnectionPositionPair, Overlay, ScrollStrategy } from '@angular/cdk/overlay';
import { CurrencyPipe, DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ButtonType } from "@ezteach/_components/buttons/button/button.component";
import { LocalDateNameSpaceService } from '@ezteach/_services/localDateNameSpace.service';
import { MediaQueryService } from '@ezteach/_services/media-query.service';
import { UserService } from "@ezteach/_services/user.service";
import {
  ChatLesson,
  ChatLessonStatusEnum,
  LessonFormatEnum,
  ScheduledLessonStatusEnum,
  Tutor
} from '@ezteach/api/models';
import { ChatLessonMember } from '@ezteach/api/models/chat-lesson-member';
import { LessonsService } from '@ezteach/api/services';
import {
  CalendarPopupService,
  CalendarService,
  GroupLessonOptions,
  ICreateCalendarMeetingPayload,
  PopupMode
} from "@ezteach/calendar";
import { CalendarApiService } from "@ezteach/calendar/services/calendar-api.service";
import { environment } from '@ezteach/enviroments';
import { ChatLessonPrivacy } from '@ezteach/group-lesson/services/group-lesson.service';
import { NavItem } from '@ezteach/my-lessons/my-lessons.component';
import { WINDOW } from "@ng-web-apis/common";
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import * as moment from 'moment';
import { Observable, Subject, fromEvent, of } from "rxjs";
import { tap } from "rxjs/operators";
@UntilDestroy()
@Component({
  selector: 'my-lesson-item',
  templateUrl: './lesson-item.component.html',
  styleUrls: ['./lesson-item.component.scss'],
  providers: [CurrencyPipe, DatePipe],
})

export class MyLessonItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  lesson: ChatLesson;
  @Input()
  activeTab: NavItem;
  @Input()
  isActiveLesson: boolean = false;
  @ViewChild('editBtn') editBtn: ElementRef;
  @ViewChild('placePopupRef') placePopupRef: ElementRef;

  linkLesson = '';
  guestInvite = '';
  disciplineName = '';
  disciplineNameShort = '';
  chatLessonStatusEnum = ChatLessonStatusEnum;
  lessonFormatEnum = LessonFormatEnum;
  buttonType = ButtonType;
  isMobile = false;
  userdata;
  cancelPopup: boolean = false;
  isOwner: boolean = false;//Пользователь является организатором

  deleteAll: number = 1;

  public isScheduled = false;
  public lessonDate: string = '';
  public lessonDateTime: string = '';
  public membersDisplayText: string = '';
  public themeLesson: string = '';
  public isPublicLesson: boolean = false;
  public tutorName: string = '';
  public countMembers: number = 0;
  scrollstrategy: ScrollStrategy;
  positionstrategy: any;
  positionsView = [
    new ConnectionPositionPair(
      { originX: 'center', originY: 'top' },
      { overlayX: 'center', overlayY: 'bottom' },
      0, -6
    ),
    new ConnectionPositionPair(
      { originX: 'center', originY: 'bottom' },
      { overlayX: 'center', overlayY: 'top' },
      0, -6
    ),
    new ConnectionPositionPair(
      { originX: 'end', originY: 'bottom' },
      { overlayX: 'center', overlayY: 'top' },
      0, -6

    ), new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'center', overlayY: 'bottom' },
      0,
      0
    )
  ];

  public timeoutCopyLink = null;

  public isDateExpire = false;
  months = '';
  days = '';
  hours = '';


  private mediaQueryService = new MediaQueryService('(max-width: 1279.9px)');

  constructor(
    private localDateNameSpaceService: LocalDateNameSpaceService,
    private calendarPopupService: CalendarPopupService,
    private cdr: ChangeDetectorRef,
    private readonly overlay: Overlay,
    @Inject(WINDOW) private readonly windowRef: Window,
    private userService: UserService,
    private translocoService: TranslocoService,
    private calendarService: CalendarService,
    private calendarApiService: CalendarApiService,
    private clipboard: Clipboard,
    private lessonsService: LessonsService,
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    const { lesson } = changes;
    if (lesson?.currentValue) {
      const chatLesson = lesson.currentValue as ChatLesson;
      this.linkLesson = environment.appUrl + '/wait?id=' + chatLesson.joinIdentity;
      this.guestInvite = `${environment.authUrl}/Auth/RegisterGuest?ReturnUrl=` + encodeURIComponent(`${window.location.origin}/wait?id=${chatLesson.joinIdentity}&invite=${chatLesson.inviteIdentity}`),

        this.themeLesson = lesson.currentValue.subject;

      const allMembers = lesson.currentValue.members;
      if (this.lesson.members.filter(m => m.isTutor).length == 1) {//Если один препод
        var tutor = this.lesson.members.find(m => m.isTutor)?.user;
        if (tutor && tutor.id == this.userdata?.id) {
          this.isOwner = true;
        }
      }
      else {//Если несколько (возможно в откуытом занятии)
        var tutor = this.lesson.members.find(m => m.role == "Owner")?.user;
        if (tutor && tutor.id == this.userdata?.id) {
          this.isOwner = true;
        }
      }

      if (allMembers?.length) {
        this.tutorName = allMembers.find((v: ChatLessonMember) => v.isTutor)?.name || "";

        const membersWithoutTutor = allMembers.filter((v: ChatLessonMember) => !v.isTutor);
        this.countMembers = membersWithoutTutor?.length == 0 ? lesson?.currentValue?.invitedUsers?.length ?? 0 : membersWithoutTutor?.length;

        this.membersDisplayText = this.countMembers > 1
          ? "Всего участников " + this.countMembers
          : membersWithoutTutor?.length
            ? membersWithoutTutor[0].name
            : lesson?.currentValue?.invitedUsers?.length
              ? lesson.currentValue.invitedUsers[0].name
              : this.tutorName;
      }

      if (lesson.currentValue.publishingPolicy?.lessonPrivacy === ChatLessonPrivacy.Public) {
        this.isPublicLesson = true;
      }
      else {
        this.isPublicLesson = false;
      }

      this.lessonDate = moment(lesson.currentValue.lessonStartDate).locale('ru').format('DD MMMM');
      this.lessonDateTime = moment(lesson.currentValue.lessonStartDate).locale('ru').format('HH:mm');

      this.disciplineName = chatLesson.discipline?.name ?? $localize`Занятие №${chatLesson.id}`;
      this.disciplineNameShort = this.disciplineName?.charAt(0) ?? chatLesson.id.toString().charAt[0];
    }
  }

  ngOnInit() {
    this.userdata = this.userService.userData$.value;
    this.subscribeScroll();
    this.scrollstrategy = this.overlay.scrollStrategies.noop();
    this.positionstrategy = this.overlay.position().global().centerHorizontally().centerVertically();
    if (this.lesson.members.filter(m => m.isTutor).length == 1) {
      var tutor = this.lesson.members.find(m => m.isTutor)?.user;
      if (tutor && tutor.id == this.userdata.id) {
        this.isOwner = true;
      }
    }
    else {
      var tutor = this.lesson.members.find(m => m.role == "Owner")?.user;
      if (tutor && tutor.id == this.userdata.id) {
        this.isOwner = true;
      }
    }
    this.mediaQueryService.match$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.isMobile = x;
          if (this.isMobile) {

          }
        }),
      )
      .subscribe();
    this.isScheduled = this.lesson.lessonStatusId === ScheduledLessonStatusEnum.Accepted;
  }


  readonly scrollY$ = new Subject<void>();
  private currentScrollY = 0;

  getExpireTime() {
    if (this.lesson.deletedAt !== null) {
      const expireDate = moment(this.lesson.deletedAt);
      const now = moment();

      const months = expireDate.diff(now, 'months');
      now.add(months, 'months');
      if (months > 0) {
        this.months = this.localDateNameSpaceService.relativeTimeName(months, true, 'month');
      }

      const days = expireDate.diff(now, 'days');
      now.add(days, 'days');
      if (days > 0) {
        this.days = this.localDateNameSpaceService.relativeTimeName(days, true, 'day');
      }

      const hours = expireDate.diff(now, 'hours');
      now.add(hours, 'hours');
      if (hours > 0) {
        this.hours = this.localDateNameSpaceService.relativeTimeName(hours, true, 'hour');
      }

      if (!this.months && !this.days && !this.hours) {
        this.isDateExpire = true;
      }
    }
  }

  public stopPropagationtMenu(event: Event) {
    event.stopPropagation()
  }

  public delete() {
    this.lessonsService.apiV1LessonsDestroy({
        lessonId: this.lesson.id,
      })
      .subscribe(response => {
        this.calendarApiService.refetchCalendarEvents$.next();
        this.cdr.detectChanges();
      });
  }

  public restore() {
    this.lessonsService.apiV1LessonsRemovedRestore({
        lessonId: this.lesson.id,
      })
      .subscribe(response => {
        this.calendarApiService.refetchCalendarEvents$.next();
        this.cdr.detectChanges();
      });
  }

  public copyLink() {

    var greeting = this.userdata.name + this.translocoService.translate(" приглашает вас на ") + this.translocoService.translate(this.isPublicLesson ? "открытое" : "закрытое") + this.translocoService.translate(" занятие обучающей платформы «Легко учиться».") + '\n\n'
      + this.translocoService.translate("Предмет") + ": " + this.translocoService.translate(this.disciplineName) + "\n\n" +
      this.translocoService.translate("Тема") + ": " + this.themeLesson + "\n\n" +
      this.translocoService.translate("Время") + ": " + this.lessonDate + " " + this.lessonDateTime + "\n\n" +
      this.translocoService.translate("Перейти на занятие") + ": " + this.linkLesson +
      (this.isPublicLesson ? (("\n\n") + this.translocoService.translate("Гостевой доступ") + ": " + this.guestInvite) : "") + "."
    this.clipboard.copy(greeting);

    this.timeoutCopyLink = setTimeout(() => {
      this.timeoutCopyLink = null;
      clearTimeout(this.timeoutCopyLink);

      this.cdr.detectChanges();
    }, 3000);
  }

  public openEditMeetingPopup(): void {
    const start = new Date(this.lesson.lessonStartDate);
    const end = new Date(this.lesson.lessonFinishDate);

    const meetingTimeChange$: Observable<ICreateCalendarMeetingPayload> = of({
      start,
      end,
      id: '',
    });

    const updatePosition$ = this.scrollY$.asObservable();
    const tutor = this.userService.userData$.value.tutor as Tutor;
    const groupLessonSettings: GroupLessonOptions = {
      lessonId: this.lesson.id,
      //@ts-ignore
      selectedDisciplineId: this.lesson.disciplineId,
      disciplines: tutor.disciplineSpecializations.map(d => d.discipline),
      selectedPublishingPolicy: this.lesson.publishingPolicy,
      meetingIsCreated: true,
      lessonWillBeRecorded: this.lesson.lessonWillBeRecorded,
      intervalId: this.lesson.intervalId,
      joinIdentity: this.lesson.joinIdentity,
      inviteIdentity: this.lesson.inviteIdentity,
      subject: this.lesson.subject,
      homeWorkTemplateId: this.lesson?.homeWorkTemplateId,
      homeWorkTemplateName: this.lesson?.homeWorkTemplateName,
      invitedUsers: this.lesson?.invitedUsers,
    };

    const data = {
      meetingTimeChange$,
      updatePosition$,
      groupLessonOptions: groupLessonSettings,
      canEditTime: true,
      isCreateFromWidget: true,
      popupMode: PopupMode.Edit,
    };
    this.calendarPopupService.onlyGroupLessonTab$.next(true);
    this.calendarPopupService.isGroupLesson$.next(true);
    const popupRef = this.calendarPopupService.openCreateMeetingPopup(this.placePopupRef?.nativeElement, data);
    popupRef.afterClosed().toPromise().then(
      (closeData) => {
        if (closeData === null) {
          this.cancelLesson(this.lesson.id);
        }
        this.calendarApiService.refetchCalendarEvents$.next();
      }
    );
  }

  public openMeetingPopup(): void {
    const popupRef = this.calendarPopupService.openMeetingPopup(this.placePopupRef?.nativeElement, this.lesson);
    popupRef.afterClosed().toPromise().then(
      (closeData) => {
        // -_-
      }
    );
  }

  public cancelLesson(id) {
    this.calendarService
      .apiV1CalendarLessonsLessonIdCancelPatch({
        lessonId: id,
      })
      .subscribe(_ => {
        this.calendarApiService.refetchCalendarEvents$.next()
      });
  }

  public showCancelPopup() {
    if (!this.cancelPopup) {
      if (this.lesson.intervalId && this.lesson.intervalId != 0) {
        this.cancelPopup = true;
      }
      else {
        this.cancelLesson(this.lesson.id)
      }
    }
  }

  confirmDeletion(id): void {//Подтверждение удаления
    if (this.deleteAll == 1) {
      this.cancelLesson(id)
    }
    else {
      this.calendarService.apiV1CLessonIntervalsDelete(id).subscribe(() => {
        this.calendarApiService.refetchCalendarEvents$.next()
      });
    }
  }

  cancelDeletion($event) {
    $event.stopPropagation();
    this.deleteAll = 1;
    this.cancelPopup = false;
    this.cdr.detectChanges();
  }

  public cancelChatRequestLesson(joinIdentity: string) {
    this.lessonsService
      .apiV1LessonsCancelPatchPath$Response({
        'joinIdentity': joinIdentity,
      })
      .subscribe(response => {
        this.calendarApiService.refetchCalendarEvents$.next();
      });
  }

  private subscribeScroll(): void {
    fromEvent(this.windowRef, 'scroll')
      .pipe(
        tap(() => {
          const update = Math.abs(this.windowRef.scrollY - this.currentScrollY);
          if (update > 50 && this.isMobile) {
            this.currentScrollY = this.windowRef.scrollY;
            this.scrollY$.next();
            this.cdr.detectChanges();
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.calendarPopupService.hideCurrentPopup();
  }
}
