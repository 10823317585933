import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SmileyType } from '@ezteach/_components/popups/popup-with-bg/popup-with-bg.component';
import { LessonsService } from '@ezteach/api/services';

@Component({
    selector: 'modal-lesson-rating',
    templateUrl: 'modal-lesson-rating.component.html',
    styleUrls: ['modal-lesson-rating.component.scss']
})
export class ModalLessonRating {
    smileyType = SmileyType;

    selectedRating: number;

    constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<ModalLessonRating>, public lessonsService: LessonsService) { }

    close() {
        this.lessonsService.addRating(this.data.lessonId, this.selectedRating)
            .subscribe(null, null, () => this.dialogRef.close('close'));
    }

    setRating(rating: number) {
        this.selectedRating = rating;
    }
}
