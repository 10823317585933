<ezteach-popup-with-bg>
    <div custom>
        <div>{{ 'Занятие окончено' | transloco }}</div>
        <div>{{ 'Вам понравилось?' | transloco }}</div>
        <div>{{ 'Репетитор' | transloco }}</div>
        <div>{{data.tutor}}</div>
        <div class="rating">
            <input
                type="radio"
                id="star5"
                name="rating"
                value="5"
                [checked]="selectedRating === 5"
                (change)="setRating(5)"
            /><label
                for="star5"
                title="5 звёзд"
            ></label>
            <input
                type="radio"
                id="star4"
                name="rating"
                value="4"
                [checked]="selectedRating === 4"
                (change)="setRating(4)"
            /><label
                for="star4"
                title="4 звезды"
            ></label>
            <input
                type="radio"
                id="star3"
                name="rating"
                value="3"
                [checked]="selectedRating === 3"
                (change)="setRating(3)"
            /><label
                for="star3"
                title="3 звезды"
            ></label>
            <input
                type="radio"
                id="star2"
                name="rating"
                value="2"
                [checked]="selectedRating === 2"
                (change)="setRating(2)"
            /><label
                for="star2"
                title="2 звезды"
            ></label>
            <input
                type="radio"
                id="star1"
                name="rating"
                value="1"
                [checked]="selectedRating === 1"
                (change)="setRating(1)"
            /><label
                for="star1"
                title="1 звезда"
            ></label>
        </div>
        <div>
            <span *ngIf="selectedRating >=1 && selectedRating <= 3">{{ 'Плохо' | transloco}}</span>
            <span *ngIf="selectedRating == 4">{{ 'Хорошо' | transloco}}</span>
            <span *ngIf="selectedRating == 5">{{ 'Отлично' | transloco}}</span>
        </div>
        <div>{{ 'Поставьте оценку от 1 до 5, где 5 - отлично' | transloco }}</div>
        <div></div>
        <ezteach-btn
            (click)="close()"
            [disabled]="selectedRating == null"
        >
            <div content>{{ 'Готово' | transloco }}</div>
        </ezteach-btn>
    </div>
</ezteach-popup-with-bg>