export const environment = {
  production: false,
  name: 'test',
  appUrl: window?.__RUNTIME_ENV__?.APP_URL,
  apiUrl: window?.__RUNTIME_ENV__?.API_URL,
  authUrl: window?.__RUNTIME_ENV__?.AUTH_URL,
  spritePath: window?.__RUNTIME_ENV__?.SPRITE_PATH,
  jivoUrl: window?.__RUNTIME_ENV__?.JIVO_URL,
  landingUrl: window?.__RUNTIME_ENV__?.LANDING_URL,
  appVersion: window?.__RUNTIME_ENV__?.APP_VERSION,
  sentryDsn: window?.__RUNTIME_ENV__?.SENTRY_DSN
};
